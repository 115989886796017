@font-face {
  font-family: 'Prompt-Regular';
  src: url('assets/fonts/Prompt-Regular.ttf');
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Prompt-Regular", sans-serif;
}

.bg-image {
  background-image: url('./assets/images/bg2.png');
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.content {
  padding: 5px 30px 5px 30px;
}

.date-time {
  margin-bottom: 70px;
}

.text-over-img {
  color: white;
  font-size: 18px;
  z-index: 1;
  margin-top: -113px;
  margin-left: -10px;
}

.date-time-over-img {
  color: white;
  z-index: 2;
  /* margin-top: -10px; */
}

.ones-prize {
  border: 1px solid blue;
  background-color: #fff;
  border-radius: 50px;
  padding: 5px 30px;
  /* width: fit-content; */
  margin: 0px auto 15px auto;
  display: flex;

  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  -webkit-transition: .4s;
  transition: .4s;
}

.two-prize {
  border: 1px solid rgb(57, 57, 162);
  background-color: #fff;
  border-radius: 50px;
  padding: 5px 30px;
  /* width: 400px; */
  margin: 0px auto 20px auto;
  /* display: flex; */
  text-align: center;

  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  -webkit-transition: .4s;
  transition: .4s;
}

.card {
  border-color: #004db3;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 15px 15px 20px 20px;
}

.card-title {
  background-color: #004db3;
  border-radius: 13px 13px 0px 0px;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.card-body {
  padding: 0px;
}

.card-text {
  font-size: 50px;
  font-weight: bold;
}

.statistic-content {
  /* background-color: #fff; */
  /* color: white; */
  max-width: 500px;
  /* border: 2px solid #de0d9d; */
  margin: 0px auto 5px auto;
  padding: 5px 10px 5px 10px;

  /* -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  -webkit-transition: .4s;
  transition: .4s; */
}

.table>thead>tr>th {
  background-color: #004db3;
  color: white;
  /* border-radius: 15px 15px 20px 20px; */
}

/* .roll {
  border-radius: 15px 15px 20px 20px !important;
} */

/* th {
  background-color: #004db3;
  color: white;
} */